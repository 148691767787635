// components/GameFilterBar.tsx
import Link from 'next/link';
import clsx from 'clsx';
import { GameFilter, useMyContext } from '../context';

interface Game {
  label: string;
  name: string;
  icon: React.ReactNode;
  href: string;
}

interface GameFilterBarProps {
  games: Game[];
  className?: string;
}

const GameFilterBar: React.FC<GameFilterBarProps> = ({ games, className }) => {
  const { value, setValue } = useMyContext();

  return (
    <div className={clsx('flex flex-row w-full bg-medium-gray', className)}>
      <div className="mx-auto flex w-full max-w-[73.75rem] items-center space-x-4 py-[0.5rem]">
        {games.map((item) => {
          const isActive = value === item.name;

          return (
            <Link
              key={item.name}
              href={item.href}
              onClick={() => setValue(item.name as GameFilter)}
              className={clsx(
                'flex flex-row gap-2 rounded-lg px-5 py-1 text-sm font-bold items-center',
                isActive
                  ? 'bg-[#192934] text-white'
                  : 'hover:bg-light-gray text-white'
              )}
            >
              {item.icon}
              {item.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default GameFilterBar;
