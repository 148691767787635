// components/UserActions.tsx
import Link from 'next/link';
import usePlayer from '@/hooks/usePlayer';
import clsx from 'clsx';
import { useRegisterDialog } from '@/components/registrationModalContext';
import Loader from '@/components/loader';
import Button from '@/components/button';
import { getCookie } from 'cookies-next';

interface UserActionsProps {
  onLoginClick: () => void;
  className?: string;
}

const UserActions: React.FC<UserActionsProps> = ({
  onLoginClick,
  className,
}) => {
  const accessToken = getCookie('access_token');
  const { toggleIsOpen } = useRegisterDialog();
  const { data: player, isLoading: isPlayerLoading } = usePlayer();

  if (isPlayerLoading) {
    return <Loader />;
  }

  return (
    <div className={clsx('flex items-center w-full', className)}>
      {player && accessToken ? (
        <Link
          className="w-full border border-solid rounded-lg font-extrabold text-sm text-center min-w-fit px-5 py-3 bg-light-blue border-light-blue text-black"
          href="/profile"
        >
          Profile
        </Link>
      ) : (
        <>
          <span
            className="hidden md:block text-sm mr-4 cursor-pointer"
            onClick={onLoginClick}
          >
            Login
          </span>
          <Button
            variant="primary"
            onClick={() => toggleIsOpen()}
            className="w-36"
          >
            Register
          </Button>
        </>
      )}
    </div>
  );
};

export default UserActions;
