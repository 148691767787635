'use client';

import { useState } from 'react';
import MenuIcon from '@public/icons/menu.svg';
import CloseIcon from '@public/icons/close.svg';
import DotaIcon from '@public/icons/games/dota.svg';
import CSIcon from '@public/icons/games/cs.svg';
import TwitterIcon from '@public/icons/socials/twitter.svg';
import YoutubeIcon from '@public/icons/socials/youtube.svg';
import InstagramIcon from '@public/icons/socials/instagram.svg';
import TikTokIcon from '@public/icons/socials/tiktok.svg';
import Logo from '@/components/header/logo';
import NavigationMenu from '@/components/header/navigationMenu';
import UserActions from '@/components/header/userActions';
import GameFilterBar from '@/components/header/gameFilterBar';
import MobileMenu from '@/components/header/mobileMenu';
import LoginDialog from '@/components/header/loginDialog';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen((prev) => !prev);
  const toggleLoginDialog = () => setLoginDialogOpen((prev) => !prev);

  const navigation = [
    { name: 'Home', href: '/home' },
    { name: 'News', href: '/news' },
    { name: 'Matches', href: '/matches' },
    {
      name: 'Betting HUB',
      matchPath: '/hub',
      subLinks: [
        { name: 'Bookmakers', href: '/hub' },
        { name: 'Bonuses', href: '/hub/bonuses' },
      ],
    },
    { name: 'Tips', href: '/tips' },
    {
      name: 'Pick’ems',
      matchPath: '/pickems',
      subLinks: [
        {
          name: 'Dota 2',
          href: '/pickems?game=dota',
          icon: <DotaIcon className="w-4 text-red" />,
        },
        {
          name: 'Counter-Strike 2',
          href: '/pickems?game=cs',
          icon: <CSIcon className="w-4 text-orange" />,
        },
      ],
    },
  ];

  const social = [
    {
      name: 'Twitter',
      href: 'https://x.com',
      icon: TwitterIcon,
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com',
      icon: YoutubeIcon,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com',
      icon: InstagramIcon,
    },
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com',
      icon: TikTokIcon,
    },
  ];

  const games = [
    { label: 'All', name: 'ALL', icon: null, href: '#' },
    {
      name: 'DOTA',
      label: 'Dota 2',
      icon: <DotaIcon className="w-4 text-white" />,
      href: '#',
    },
    {
      name: 'CS',
      label: 'CS 2',
      icon: <CSIcon className="w-4 text-white" />,
      href: '#',
    },
  ];

  return (
    <header className="min-w-screen text-white inset-x-0 sticky top-0 z-50 flex flex-col bg-transparent">
      {/* Header Top */}
      <div className="flex flex-row w-full bg-dark-gray h-[4.5rem] z-10">
        <div className="p-4 md:p-0 mx-auto flex w-full max-w-[73.75rem] items-center justify-between">
          <Logo className="mr-[2.875rem]" />
          <NavigationMenu
            navigation={navigation}
            className="gap-x-2 text-sm font-semibold"
          />
          <UserActions onLoginClick={toggleLoginDialog} className="ml-4" />
          <div className="md:hidden flex items-center justify-end ml-4">
            <button
              type="button"
              className="-m-3 p-3"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </button>
          </div>
        </div>
      </div>
      {/* Header Bottom */}
      {!mobileMenuOpen && <GameFilterBar games={games} />}
      {/* Mobile Menu */}
      <MobileMenu
        open={mobileMenuOpen}
        toggle={toggleMobileMenu}
        navigation={navigation}
        social={social}
      />
      {/* Login Dialog */}
      <LoginDialog open={loginDialogOpen} toggle={toggleLoginDialog} />
    </header>
  );
};

export default Header;
