import { QueryClient, QueryFunctionContext } from '@tanstack/react-query';
import axiosInstance from './axios';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }: QueryFunctionContext) => {
        const endpoint = queryKey[0] as string;
        const { data } = await axiosInstance.get(endpoint);
        return data;
      },
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

export default queryClient;
