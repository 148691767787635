// components/Logo.tsx
import Link from 'next/link';
import { GoCoreLogo } from '../logo';
import clsx from 'clsx';

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => (
  <Link href="/">
    <GoCoreLogo className={clsx(className)} />
  </Link>
);

export default Logo;
