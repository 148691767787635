// components/MobileMenu.tsx
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Close from '@public/icons/close.svg';
import Link from 'next/link';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

interface SubLink {
  name: string;
  href: string;
  icon?: React.ReactNode;
}

interface NavigationItem {
  name: string;
  href?: string;
  subLinks?: SubLink[];
}

interface SocialItem {
  name: string;
  href: string;
  icon: React.FC<any>;
}

interface MobileMenuProps {
  open: boolean;
  toggle: () => void;
  navigation: NavigationItem[];
  social: SocialItem[];
  className?: string;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  open,
  toggle,
  navigation,
  social,
  className,
}) => {
  const pathname = usePathname();

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="lg:hidden" onClose={toggle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-x-[-100%]"
          enterTo="opacity-100 translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-[-100%]"
        >
          <Dialog.Panel
            className={clsx(
              'fixed inset-y-0 left-0 z-40 w-full overflow-y-auto bg-medium-gray text-white p-4 flex flex-col',
              className
            )}
          >
            <div className="flex justify-end h-16 items-center">
              <button type="button" className="-m-2.5 p-2.5" onClick={toggle}>
                <span className="sr-only">Close menu</span>
                <Close />
              </button>
            </div>
            <div className="flex flex-col flex-1 justify-between">
              <div className="mt-5 space-y-2">
                <Link className="px-3" href="/login">
                  Login
                </Link>
                {navigation.map((item) => {
                  const isActive = pathname.includes(item.href || '');

                  return (
                    <Fragment key={item.name}>
                      {item.subLinks ? (
                        <div className="flex flex-col">
                          <p className="leading-5 px-3 text-base font-medium text-white rounded-lg py-[0.906rem]">
                            {item.name}
                          </p>
                          <div className="mt-2 space-y-2 ml-5">
                            {item.subLinks.map((link) => (
                              <Link
                                key={link.name}
                                href={link.href}
                                onClick={toggle}
                                className={clsx(
                                  'block rounded-lg leading-5 px-3 text-base font-medium text-white py-[0.906rem]',
                                  pathname === link.href &&
                                    'font-bold bg-light-blue-o10 border border-solid border-light-blue-o50 text-dark-gray'
                                )}
                              >
                                {link.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <Link
                          key={item.name}
                          href={item.href || '#'}
                          onClick={toggle}
                          className={clsx(
                            'block rounded-lg leading-5 px-3 text-base font-medium text-white py-[0.906rem]',
                            isActive &&
                              'font-bold bg-light-blue-o10 border border-solid border-light-blue-o50 text-dark-gray'
                          )}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 px-3">
                  {social.map((item) => (
                    <Link
                      target="_blank"
                      key={item.name}
                      href={item.href}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default MobileMenu;
