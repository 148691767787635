// components/LoginDialog.tsx
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GoCoreLogo } from '../logo';
import { XMarkIcon } from '@heroicons/react/24/solid';
import LoginForm from '../forms/login';
import clsx from 'clsx';

interface LoginDialogProps {
  open: boolean;
  toggle: () => void;
  className?: string;
}

const LoginDialog: React.FC<LoginDialogProps> = ({
  open,
  toggle,
  className,
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggle}>
        {/* Backdrop */}
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* The backdrop needs to be a single element */}
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        {/* Dialog Content */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-[-10%]"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-[-10%]"
            >
              {/* The Dialog.Panel is the child receiving the transition props */}
              <Dialog.Panel
                className={clsx(
                  'w-full max-w-lg transform overflow-hidden rounded-2xl bg-light-gray px-4 py-5 md:p-12 text-left align-middle shadow-xl transition-all',
                  className
                )}
              >
                <div className="flex items-center justify-between mb-5">
                  <GoCoreLogo />
                  <button type="button" onClick={toggle}>
                    <XMarkIcon className="h-9 w-9 text-white" />
                  </button>
                </div>
                <LoginForm onLogin={toggle} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoginDialog;
