// components/NavigationMenu.tsx
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

interface SubLink {
  name: string;
  href: string;
  icon?: React.ReactNode;
}

interface NavigationItem {
  name: string;
  href?: string;
  subLinks?: SubLink[];
  matchPath?: string; // Add this optional property
}

interface NavigationMenuProps {
  navigation: NavigationItem[];
  className?: string;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  navigation,
  className,
}) => {
  const pathname = usePathname();

  return (
    <nav className={clsx('hidden md:flex flex-1', className)}>
      {navigation.map((item) => {
        const hasSubLinks = item.subLinks && item.subLinks.length > 0;

        // Determine active state:
        // 1. If matchPath is provided, use it.
        // 2. Otherwise, if href is provided, use that.
        let isActive = false;
        if (item.matchPath) {
          isActive = pathname.includes(item.matchPath);
        } else if (item.href) {
          isActive = pathname.includes(item.href);
        }

        if (hasSubLinks) {
          return (
            <Popover className="relative" key={item.name}>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={clsx(
                      'px-3 py-2 border rounded-lg flex items-center',
                      'hover:bg-light-blue-o10 hover:border-light-blue-o50',
                      'focus:outline-none',
                      open || isActive
                        ? 'font-bold bg-light-blue border-light-blue text-dark-gray'
                        : 'border-dark-gray text-white'
                    )}
                  >
                    <span>{item.name}</span>
                    {open ? (
                      <ChevronUpIcon className="ml-2 h-5 w-5 transition duration-150 ease-in-out" />
                    ) : (
                      <ChevronDownIcon className="ml-2 h-5 w-5 transition duration-150 ease-in-out" />
                    )}
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 mt-5 flex px-4 transition w-max">
                    <div className="flex-auto overflow-hidden rounded-3xl bg-dark-gray text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        {item.subLinks?.map((subItem) => (
                          <div
                            key={subItem.name}
                            className="group relative flex gap-2 rounded-lg p-4 hover:bg-light-blue-o10"
                          >
                            <Link
                              href={subItem.href}
                              className=" font-medium text-white"
                            >
                              {subItem.name}
                            </Link>
                            {subItem.icon && subItem.icon}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          );
        }

        // For top-level items without subLinks, just a link
        return (
          <Link
            key={item.name}
            href={item.href || '#'}
            className={clsx(
              'px-3 py-2 border rounded-lg',
              'hover:bg-light-blue-o10 hover:border-light-blue-o50',
              isActive
                ? 'font-bold bg-light-blue border-light-blue text-dark-gray'
                : 'border-dark-gray text-white'
            )}
          >
            {item.name}
          </Link>
        );
      })}
    </nav>
  );
};

export default NavigationMenu;
